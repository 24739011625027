/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

p {
  font-size: 24px;

  @media (max-device-width: 760px) {
    font-size: 16px;
  }
  padding: 0 0 1.5rem 0;
}

$text-mint : #77ddde;
$text-green : lightgreen;
$text-base-size: 1.125rem;
$text-base-color: lightgrey;
